export const TICK = {
  env: "uat",
  auth: {
    accessToken: null,
    role: null,
    userId: null,
  },
};

export const envConfig = {
  dev: {
    APP_HOST: "/",
    APP_PORT: "3000",
    API_HOST: "192.168.88.165",
    API_PORT: "1881",
    replication: true,
  },
  dev2: {
    APP_HOST: "/",
    APP_PORT: "3000",
    API_HOST: "www.luxholichk.com",
    API_PORT: "8081",
    replication: true,
  },
  dev3: {
    APP_HOST: "/",
    APP_PORT: "3000",
    // API_HOST: "127.0.0.1",
    // API_HOST: "192.168.88.165",
    API_HOST: "localhost",
    API_PORT: "9001",
    replication: true,
  },
  uat: {
    // basePath: "/UAT",
    // port: "1880",
    APP_HOST: "pos-uat.luxholichk.com",
    API_HOST: "pos-uat.luxholichk.com",
    API_PORT: "",
    replication: true,
  },
  prod: {
    APP_HOST: "pos.luxholichk.com",
    API_HOST: "pos.luxholichk.com",
    API_PORT: "",
    replication: true,
  },
  //   prod: {
  //     basePath: "/PROD",
  //     replication: true,
  //   },
};

export const endpoint = {
  baseUrl: `${TICK['env'] === 'dev3' ? 'http' : 'https'}://${envConfig[TICK.env].API_HOST}${
    envConfig[TICK.env].API_PORT !== ""
      ? `:${envConfig[TICK.env].API_PORT}`
      : ""
  }`,
};

export const printer = {
  printerIp: null,
  printerPort: null,
  printerId: null
}