import React, { useEffect, forwardRef, memo, useRef } from "react";
import styles from './tickContract.module.scss';
import logo from "../../assets/img/invWatermark.png"

const TickContract = forwardRef(({ contractData }, ref) => {
  const renderPages = () => {
    const itemsPerPage = contractData.type == "purchase" ? 4 : 3
    const pdfPageCount = Math.ceil(contractData.items.length / itemsPerPage)
    const newItemList = [];
    const itemKey = []
    const pages = [];
    const totalAmount = contractData.items.reduce((accumulator, item) => accumulator + parseInt(item.directInCost), 0);

    for (let i = 0; i < pdfPageCount; i++) {
      let tempArr = contractData.items.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage);
      let startItemKey = i * itemsPerPage + 1;
      let endItemKey = (i + 1) * itemsPerPage;
      let tempItemKey = []
      for (let i = startItemKey; i <= endItemKey; i++) {
        tempItemKey.push("item " + i)
      }
      itemKey.push(tempItemKey)
      newItemList.push(tempArr);
    }

    for (let i = 0; i < pdfPageCount; i++) {
      pages.push(
        <div className={styles.container}>
          <ContractHeader contractNum={contractData.contractNum} type={contractData.type} />
          <Consignor consignor={contractData.consignor} />
          <ItemList items={newItemList[i]} itemKey={itemKey[i]} type={contractData.type} />
          {
            contractData.type == "purchase" ?
              <>
                <DirectInContractTotalAmount totalAmount={totalAmount} />
                <DirectInContractNotice />
              </> :
              <>
                <Clause items={newItemList[i]} />
                <Notice />
              </>
          }
          <Signature contractDate={contractData.contractDate} type={contractData.type} />
        </div>
      );
    }
    return pages;
  };

  return <div className={styles.root} ref={ref}>{renderPages()}</div>;
})

const ContractHeader = (props) => {
  return (
    <div className={styles.contractHeader}>
      <div className={styles.lhLogo}>
        <img src={logo} alt="" width={"100%"} />
      </div>
      <div className={styles.contractHeaderInfo}>
        <div>
          <p>Tel : +852 9844 1216</p>
          <p>Address : Shop 02-05, G/F, Kimberley Plaza, Tsim Sha Tsui</p>
          <p>地址 : 香港尖沙嘴⾦巴利道⾦巴利廣場地下02-05號鋪</p>
          <p>Facebook : Luxholic.HK Instagram : luxholic.hk</p>
        </div>
        <div className={styles.contractNum}>合約編號： <span>{props.contractNum}</span></div>
        <div className={styles.contractNum}>{props.type == 'purchase' ? "商品收購收據" : "商品代理銷售合約"}</div>
      </div>
    </div>
  )
}

const Consignor = (props) => {
  return (
    <div className={styles.consignor}>
      <div className={styles.consignorInfo}>
        <div className={styles.line}>
          <span>姓名： </span>
          <span className={styles.value}>{props.consignor?.fullName}</span>
        </div>
        <div className={styles.line}>
          <span>聯絡電話：</span>
          <span className={styles.value}>{props.consignor?.phoneNum}</span>
        </div>
      </div>
    </div>
  )
}

const ItemList = (props) => {
  return (
    <div className={styles.itemList}>
      {
        props.type == "purchase" ?
          <div className={styles.directInItemListTitle}>
            <div >本人為以下貨物的合法擁有者，並同意向 Luxholic.HK Limited 出售以下商品：</div>
            <div>
              I am the legal owner of the following item(s), and have agreed to sell the following item(s) to Luxholic.HK Limited :
            </div>
          </div>
          :
          <div className={styles.consignmentItemListTitle}>本人現委託Luxholic.hk Limited依下列條款代理出售以下商品：</div>
      }
      {props.items.map((item, index) => {

        const formatAmt = (amt) => {
          if (amt === null || amt === undefined || amt === 0) return "N/A";
          return amt;
        }

        let itemAmt = item.amt;
        if (props.type === "purchase") {
          itemAmt = formatAmt(item.directInCost);
        }

        return (
          <div className={props.type == "purchase" ? styles.directInContractItem : styles.item} key={index}>
            {props.itemKey[index]}
            <div className={styles.itemDetail}>
              <div className={styles.box}>
                <div className={styles.itemField}>
                  <div className={styles.itemKey}>品牌：</div>
                  <div className={styles.itemValue}>{item.brand}</div>
                </div>
                <div className={styles.itemField}>
                  <div className={styles.itemRating}>新淨度：</div>
                  <div className={styles.itemValue}>{item.rating}</div>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles.itemField}>
                  <div className={styles.itemKey}>名稱：</div>
                  <div className={styles.itemValue}>{item.itemNameEn}</div>
                </div>
                <div className={styles.itemField}>
                  <div className={styles.itemKey}>配件：</div>
                  <div className={styles.itemValue}>{item.accessories}</div>
                </div>
              </div>
              <div className={styles.box}>
                <div className={styles.itemField}>
                  <div className={styles.itemKey}>顏色：</div>
                  <div className={styles.itemValue}>{item.colorEn}</div>
                </div>
                <div className={styles.itemField}>
                  <div className={styles.itemKey}>報價：</div>
                  <div className={styles.itemValue}>${itemAmt}</div>
                </div>
              </div>
            </div>
            {
              props.type == "purchase" ?
                <></>
                :
                <div className={styles.remark}>
                  <div className={styles.remarkKey}>備註：</div>
                  <div className={styles.remarkValue}>{item.remark}</div>
                </div>
            }
          </div>
        )
      })}
    </div>
  )
}

const Clause = (props) => {

  const calAmt = (item) => {
    if (!item) return "N/A"
    return item.amt - item.handlingFee
  }

  return (
    <div className={styles.clause}>
      <div>
        <div>
          <input type="checkbox" disabled />
          <label className={styles.label}>
            本人同意寄賣並扣除手續費後實收①<span>{calAmt(props.items[0])}</span>/②<span>{calAmt(props.items[1])}</span>/③<span>{calAmt(props.items[2])}</span>港元
          </label>
        </div>
        <div className={styles.description}>(收取$300($2000或以下)或售價15%($2001-$49999)或售價10%($50000以上)的手續費)</div>
        <div className={styles.description}>(全新手袋於60天內購買、仍保留原有貼膜、全套配件及正單收取售價10%的手續費)</div>
      </div>
      <div>
        <input type="checkbox" disabled />
        <label className={styles.label}>本人同意 Luxholic.HK Limited 有權把以上貨品的售價作 合理調整^ 而毋須通知賣家</label>
      </div>
      <div className={styles.clauseRemark}>(合理調整：不少於/不多於當初報價的15%)</div>
    </div>
  )
}

const DirectInContractTotalAmount = (props) => {
  return (
    <div className={styles.directInTotalAmount}>
      <div>本人願意出售上述商品，並收回（合共） {props.totalAmount} 港元。</div>
      <div>I agree to sell the above items in a sum of {props.totalAmount} Hong Kong Dollars.</div>
    </div>
  )
}

const DirectInContractNotice = () => {
  return (
    <div className={styles.notice}>
      <div>注意事項: </div>
      <div className={styles.list}>
        <ol>
          <li>
            本人乃上述商品之合法物主，並已閱讀、明白及同意背頁之「商品寄售條款」
          </li>
          <li>
            本人保證所寄售商品均為有關品牌之正版真品，倘若經 Luxholic.HK Limited 檢驗後發現非正版真品，本人須賠償每件貨品 $500 元的行政費用。
          </li>
          <li>
            以上的商品經驗證真偽後，本公司會在該收購收據簽署日期三個工作天內經客戶提供的戶口轉賬有關款項。
          </li>
        </ol>
      </div>
      <div className={styles.directInAllow}>
        <input type="checkbox" disabled />
        <span>本人同意接收 Luxholic.HK 經 WhatsApp / Instagram / E-mail 發出的最新推廣資訊。</span>
      </div>
    </div>
  )
}

const Notice = () => {
  return (
    <div className={styles.notice}>
      <div>注意事項: </div>
      <div className={styles.list}>
        <ol>
          <li>本人乃上述商品之合法物主，並已閱讀、明白及同意背頁之「商品寄售條款」</li>
          <li>
            本人保證所寄售商品均為有關品牌之正版真品，倘若經 Luxholic.HK Limited 檢驗後發現非正版真
            品，本人須賠償每件貨品 $500 元的行政費用
          </li>
          <li>
            本人同意及明白貨品寄存於店內之一切風險（如寄賣期間，商品因陳列而造成的自然損耗，
            Luxholic.HK Limited 概不負責）
          </li>
        </ol>
      </div>
      <span className={styles.allow}>本人明白並同意，Luxholic.HK Limited 可隨時以現金或支票支付上述同意的商品貨款，而不需將商品歸還</span>
    </div>
  )
}

const Signature = (props) => {
  return (
    <div className={styles.signature}>
      <div className={styles.signLine}>
        <span>{props.type == "purchase" ? "賣家簽署：" : "寄賣人簽署: "}</span>
        <span className={styles.value}><span className={styles.underLine}></span></span>
      </div>
      <div className={styles.signLine}>
        <span>職員簽署：</span>
        <span className={styles.value}><span className={styles.underLine}></span></span>
      </div>
      <div className={styles.date}>
        <span>日期：</span>
        <span className={styles.value}>{props.contractDate}</span>
      </div>
    </div>
  )
}

export default memo(TickContract);